.authenticate-back
{
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: grid;
	grid-template-rows: 1fr auto 1fr;
}

.authenticate-center
{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.authenticate-buttons
{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
}

.authenticate-window
{
  width: 300px;
  height: auto;
  min-height: 300px;
}

.authenticate-logo
{
  position: relative;
  float: left;
  width: 300px;
  height: 150px;
}

.authenticate-title
{
  position: relative;
  float: left;
  height: 40px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  width: 100%;
}

.authenticate-text
{
  position: relative;
  float: left;
  height: auto;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  width: 100%;
  text-align: center;
  color:#4a4a4a;
  margin-bottom: 20px;
  margin-top: 10px;
}

.authenticate-logo-image
{
  width: 100%;
  height: 100%;
  position: relative;
  float: left;
}

.authenticate-placeholder
{
  width: 100%;
  height: 25px;
  position: relative;
  float: left;
}

.authenticate-buttons-logout
{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 150px 1fr;
}

.authenticate-error
{
  position: relative;
  float: left;
  height: auto;
  margin-top: 15px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 17px;
}
