.ser-line-one
{
  position: relative;
  float: left;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.p-line-end
{
  position: relative;
  float: left;
  width: calc(100% - 20px);
  margin: 10px;
  display: grid;
  grid-template-columns: 150px 1fr 150px;
  grid-gap: 20px;
}

.ser-line-two
{
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 20px;
  height: 350px;
  font-family: "Titillium" !important;
  overflow-y: auto;
}

.ql-editor
{
  min-height: 300px !important;
  font-family: "Titillium" !important;
}

.pt-line-three
{
  position: relative;
  float: left;
  width: calc(100% - 20px);
  margin: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  grid-gap: 20px;
}
